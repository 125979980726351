import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
import logo from "./assets/naturheilpraxis-fourne-logo.svg";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faPhone, faEnvelope);

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>Hier entsteht in Kürze die neue Homepage für:</p>
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <div className="App-content">
          <div><p>Termine nach telefonischer Vereinbarung</p></div>
          <div>
            <FontAwesomeIcon icon="phone" rotation={90} size="xs" className="icon-label" />
            <span>02423-90 46 448 </span>
          </div>
          <div>
            <FontAwesomeIcon icon="envelope" size="xs" className="icon-label" />
            <a href="mailto:info@naturheilpraxis-fourne.de" className="App-link">
              <span>info@naturheilpraxis-fourne.de</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
